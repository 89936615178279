
import { defineComponent } from 'vue';

type Dice = {
  currentNumber: number;
  finalNumber: number;
}
export default defineComponent({
  name: 'dice-component',
  props: {
    current: Number,
  },
  computed: {
    classes(): string {
      return `dice dice_${this.current ? this.current : ''}`;
    },
  },
});
