import {
  Box3, CubicBezierCurve3, Line3, Object3D, Vector3,
} from 'three';
import TWEEN from '@tweenjs/tween.js';
import { Player } from '../types';

export default class Piece {
  object: Object3D;

  player: Player;

  diameter: number;

  height: number;

  constructor(mesh: Object3D, player: Player) {
    this.object = mesh;
    const bbox = new Box3().setFromObject(mesh);
    const max = new Vector3(bbox.max.x, 0, bbox.max.z);
    const min = new Vector3(bbox.min.x, 0, bbox.min.z);
    this.height = bbox.max.y - bbox.min.y;
    this.diameter = Math.abs(max.distanceTo(min));
    this.player = player;
  }

  moveTo(position: Vector3, duration = 1600, raise = true) {
    if (position.x !== this.object.position.x || position.z !== this.object.position.z) {
      const bbox = new Box3().setFromObject(this.object);
      const pieceHeight = bbox.max.y - bbox.min.y;
      const targetVec = new Vector3(position.x, position.y + (pieceHeight / 3), position.z);
      const currentVec = new Vector3();
      this.object.getWorldPosition(currentVec);
      if (raise) {
        const line = new Line3(currentVec, targetVec);
        const upVec = new Vector3();
        const height = Math.min(Math.max(line.distance() * 0.2, 5), 30);
        line.at(0.2, upVec);
        upVec.setY(upVec.y + height);
        const downVec = new Vector3();
        line.at(0.8, downVec);
        downVec.setY(downVec.y + height);
        const bezier = new CubicBezierCurve3(currentVec, upVec, downVec, targetVec);
        new TWEEN.Tween({ value: 0 })
          .to({ value: 1 }, duration)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate((value) => {
            const newVec = bezier.getPointAt(value.value);
            this.object.position.set(newVec.x, newVec.y, newVec.z);
          })
          .start();
      } else {
        new TWEEN.Tween(currentVec).to(targetVec, duration).easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate((value) => { this.object.position.set(value.x, value.y, value.z); })
          .start();
      }
    }
  }
}
