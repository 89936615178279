import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

import { Camera, Vector3 } from 'three';
// @ts-ignore
import OrbitControls from './utils/orbitControls';
import fullconfig from '../../config';

const config = fullconfig.threeConfig;

// Controls based on orbit controls
export default class Controls {
  threeControls: OrbitControls

  constructor(camera: Camera, container: HTMLElement) {
    // Orbit controls first needs to pass in THREE to constructor
    const OrbitControlsC = new OrbitControls(THREE);
    this.threeControls = new OrbitControlsC(camera, container);

    this.init();
  }

  init() {
    this.threeControls.target.set(
      config.controls.target.x,
      config.controls.target.y,
      config.controls.target.z,
    );
    this.threeControls.autoRotate = config.controls.autoRotate;
    this.threeControls.autoRotateSpeed = config.controls.autoRotateSpeed;
    this.threeControls.rotateSpeed = config.controls.rotateSpeed;
    this.threeControls.zoomSpeed = config.controls.zoomSpeed;
    this.threeControls.minDistance = config.controls.minDistance;
    this.threeControls.maxDistance = config.controls.maxDistance;
    this.threeControls.minPolarAngle = config.controls.minPolarAngle;
    this.threeControls.maxPolarAngle = config.controls.maxPolarAngle;
    this.threeControls.enableDamping = config.controls.enableDamping;
    this.threeControls.enableZoom = config.controls.enableZoom;
    this.threeControls.dampingFactor = config.controls.dampingFactor;
  }

  moveTo(time: number, target: Vector3, revolve: boolean) {
    this.threeControls.autoRotate = false;
    const targetVec = new THREE.Vector3(target.x, target.y, target.z);
    const currentVec = new THREE.Vector3(
      this.threeControls.target.x,
      this.threeControls.target.y,
      this.threeControls.target.z,
    );
    new TWEEN.Tween(currentVec)
      .to(targetVec, time)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate((value) => { this.threeControls.target.set(value.x, value.y, value.z); })
      .onComplete(() => { this.threeControls.autoRotate = revolve; })
      .start();
  }
}
