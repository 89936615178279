export type Point = {
  id: number;
  shortTitle: string;
  title: string;
  description: string[];
}

export enum Action {
  rollTwo = 1,
  rollOne,
  choose,
  move,
  next,
  none,
}

export enum Status {
  biili = 1,
  double,
  stay,
  reverse,
  none,
}

export type Player = {
  name: string;
  id: string;
  progress: number;
  action: Action;
  jmtCount: number;
  jmt6Visited: boolean;
  status: Status;
};

export type GameState = {
  id: string;
  players: Player[];
  turn: number;
  started: boolean;
  finished: boolean;
  dice: [number, number];
};

export type JoinPayload = {
  gameId: string;
  player: string;
}

export type StartGamePayload = {
  gameId: string;
  turn: number;
}

export type TurnPayload = {
  gameId: string;
}
