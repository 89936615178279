import TWEEN from '@tweenjs/tween.js';

// This object contains the state of the app
const threeConfig = {
  isDev: false,
  isShowingStats: true,
  isLoaded: false,
  isTweening: false,
  isRotating: true,
  isMouseMoving: false,
  isMouseOver: false,
  maxAnisotropy: 1,
  dpr: 1,
  easing: TWEEN.Easing.Quadratic.InOut,
  duration: 500,
  model: {
    selected: 0,
    initialTypes: ['gltf', 'object'],
    type: 'gltf',
  },
  fog: {
    color: 0xffffff,
    near: 0.0008,
  },
  camera: {
    fov: 60,
    near: 2,
    far: 1000,
    aspect: 1,
    posX: 0,
    posY: 2,
    posZ: 2,
  },
  controls: {
    autoRotate: false,
    autoRotateSpeed: -0.1,
    rotateSpeed: 0.3,
    zoomSpeed: 0.8,
    minDistance: 50,
    maxDistance: 60,
    minPolarAngle: Math.PI / 3,
    maxPolarAngle: Math.PI / 3,
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    enableDamping: true,
    dampingFactor: 0.5,
    enableZoom: true,
    target: {
      x: 0,
      y: 0,
      z: 0,
    },
  },
};

const config = {
  threeConfig,
  apiUrl: process.env.VUE_APP_API_LOCATION,
};

export default {
  ...config,
};
