import TWEEN from '@tweenjs/tween.js';
import {
  AmbientLight,
  Box3,
  Clock,
  CubeTextureLoader,
  DirectionalLight,
  FogExp2,
  Font,
  FontLoader,
  Group,
  Mesh, MeshStandardMaterial, Object3D, Scene, TextGeometry, Vector3,
} from 'three';
// @ts-ignore
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// @ts-ignore
import Stats from './utils/stats';
// @ts-ignore
import DatGUI from './utils/datGUI';
import Camera from './camera';
import Renderer from './renderer';
import Controls from './controls';
import fullconfig from '../../config';
import { points } from '../points';
import Piece from './piece';

const config = fullconfig.threeConfig;

export async function loadFont() {
  const loader = new FontLoader();
  return loader.loadAsync('/assets/fonts/optimer.typeface.json');
}

export function fitPieceOnPoint(
  playerNumber: number,
  position: Vector3,
  pieceDiameter: number,
) {
  const multiplier = playerNumber < 4 ? 1 : 2;
  const diameter = pieceDiameter * (multiplier / 2);
  console.log(pieceDiameter);
  const direction = playerNumber < 4
    ? ((2 * Math.PI) / 4) * (playerNumber)
    : ((2 * Math.PI) / 8) * (playerNumber - 3);
  const offset = new Vector3(Math.cos(direction), 0, Math.sin(direction)).setLength(diameter);
  const newPosition = position.clone();
  newPosition.add(offset);
  return newPosition;
}

export default class Main {
  renderer: Renderer;

  camera: Camera;

  controls: Controls;

  container: HTMLElement;

  clock: Clock;

  scene: Scene;

  stats: Stats;

  gui: DatGUI;

  locationFontMaterials: MeshStandardMaterial[];

  playerFontMaterials: MeshStandardMaterial[];

  loader: GLTFLoader;

  font: Font;

  pieceModel?: Object3D;

  pieces: Piece[]

  text?: Mesh

  constructor(container: HTMLElement, font: Font, callback: Function) {
    // Set container property to container element
    this.container = container;

    // Start Three clock
    this.clock = new Clock();

    this.pieces = [];

    // Main scene creation
    this.scene = new Scene();
    this.scene.fog = new FogExp2(config.fog.color, config.fog.near);

    // Get Device Pixel Ratio first for retina
    if (window.devicePixelRatio) {
      config.dpr = window.devicePixelRatio;
    }

    // Main renderer constructor
    this.renderer = new Renderer(this.scene, this.container);

    // Components instantiations
    this.camera = new Camera(this.renderer.threeRenderer);
    this.controls = new Controls(this.camera.threeCamera, this.container);

    // Set up rStats if dev environment
    if (config.isDev && config.isShowingStats) {
      this.stats = new Stats(this.renderer);
      this.stats.setUp();
    }

    // Set up gui
    if (config.isDev) {
      this.gui = new DatGUI(this);
    }

    this.locationFontMaterials = [
      new MeshStandardMaterial({
        color: 0x02c905, flatShading: true, metalness: 0.4, roughness: 0.7,
      }), // front
      new MeshStandardMaterial({ color: 0x173d21, metalness: 0.4, roughness: 0.7 }),
    ];

    this.playerFontMaterials = [
      new MeshStandardMaterial({
        color: 0xeb3437, flatShading: true, metalness: 0.4, roughness: 0.7,
      }), // front
      new MeshStandardMaterial({ color: 0x571516, metalness: 0.4, roughness: 0.7 }),
    ];
    const light = new AmbientLight(0xffffff, 0.5);
    // const light2 = new HemisphereLight(0xffffff, 0x666666, 0.5);
    const dirLight = new DirectionalLight(0xffffff, 0.7);
    dirLight.color.setHSL(0.1, 1, 0.95);
    dirLight.position.set(-1, 10, 1);
    dirLight.position.multiplyScalar(30);
    this.scene.add(dirLight);
    // this.scene.add(dir);
    this.scene.add(light);
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/lib/draco/');
    this.loader = new GLTFLoader();
    this.loader.setDRACOLoader(dracoLoader);
    this.loadModels(this.loader, callback);
    this.font = font;
    this.render();
  }

  async createPiece(position: Vector3, nick: string) {
    await this.pieceModel;
    const pieceModel = this.pieceModel!.clone();
    pieceModel.position.set(0, 0, 0);
    const bbox = new Box3().setFromObject(pieceModel);
    const textPosition = new Vector3();
    bbox.getCenter(textPosition);
    textPosition.setY(bbox.max.y);
    const text = this.createText(nick, textPosition, this.playerFontMaterials, false, 1.2, 2);
    const group = new Group();
    group.add(pieceModel);
    group.add(text);
    const pieceHeight = bbox.max.y - bbox.min.y;
    group.position.set(position.x, position.y + (pieceHeight / 2), position.z);
    this.scene.add(group);
    group.rotateY(Math.PI / 3);
    group.updateMatrixWorld();
    return group;
  }

  async loadModels(loader: GLTFLoader, callback: Function) {
    const path = '/assets/textures/env/';
    const format = '.png';
    const envMap = new CubeTextureLoader().load([
      `${path}px${format}`, `${path}nx${format}`,
      `${path}py${format}`, `${path}ny${format}`,
      `${path}pz${format}`, `${path}nz${format}`,
    ]);
    this.scene!.environment = envMap;
    this.scene!.background = envMap;

    await this.font;
    await this.loadModel(loader, '/assets/models/otaniemi.glb', 30).catch((e) => console.log(e));
    this.pieceModel = await this.loadModel(loader, '/assets/models/nappula.glb', 7, [175, 10, 542], false);

    callback();
  }

  moveTo(id: number, locale: string) {
    const { location, text } = this.getPointLocation(id, locale);
    if (!!location && !!text) {
      this.removeText(this.text);
      this.controls!.moveTo(2000, location, true);
      this.text = this.createText(text, location, this.locationFontMaterials);
    }
  }

  getPointLocation(id: number, locale: string): {location: null | Vector3; text?: string} {
    const currentId = id || 1;
    const text = points[locale].find((x) => x.id === id)?.shortTitle || '';
    const stringId = currentId < 10 ? `0${currentId}` : currentId.toString();
    let vec: Vector3 | null = null;
    this.scene!.traverse((x) => {
      if (x.isObject3D && x.name.includes(`${stringId}-`)) {
        vec = new Vector3();
        x.getWorldPosition(vec);
      }
    });
    return { text, location: vec };
  }

  removeText(text?: Mesh) {
    if (text) {
      const mesh = text.geometry;
      this.scene!.remove(text);
      mesh.dispose();
    }
  }

  async loadModel(
    loader: GLTFLoader, url: string, scale: number, position?: [number, number, number], add = true,
  ) {
    const instance = await loader.loadAsync(url);
    const model = instance.scene;
    model.scale.set(scale, scale, scale);
    if (position) {
      model.position.set(...position);
    } else {
      new Box3().setFromObject(model).getCenter(model.position).multiplyScalar(-1);
      model.position.setY(0);
    }
    if (add) {
      this.scene!.add(model);
    }
    /*  this.scene.traverse(x => {
      if(x.isMesh && x.name.includes('-')) {
        x.material.emissive = 0xffffff;
        x.material.emissiveIntensity = 1.5;
      }
    }) */
    this.render();
    return model;
  }

  createText(
    text: string,
    position: Vector3,
    materials: MeshStandardMaterial[],
    add = true,
    size = 2,
    yOffset = 10,
  ) {
    const textGeo = new TextGeometry(text, {
      font: this.font,
      size,
      height: size / 2,
      curveSegments: 4,
      bevelThickness: 0.02,
      bevelSize: 0.03,
      bevelEnabled: true,

    });

    textGeo.computeBoundingBox();

    const textMesh = new Mesh(textGeo, materials);
    const centerOffset = new Vector3();
    textMesh.rotation.y = Math.atan2(
      (this.controls!.threeControls.target.x - position.x),
      (this.controls!.threeControls.target.z - position.z),
    );
    textMesh.rotation.x = 0;
    new Box3().setFromObject(textMesh).getCenter(centerOffset);

    textMesh.position.x = position.x - centerOffset.x;
    textMesh.position.y = position.y + yOffset;
    textMesh.position.z = position.z - centerOffset.z;
    if (add) {
        this.scene!.add(textMesh);
    }
    return textMesh;
  }

  render() {
    // Render rStats if Dev
    if (config.isDev && config.isShowingStats) {
      Stats.start();
    }

    // Call render function and pass in created scene and camera
    this.renderer!.render(this.scene!, this.camera!.threeCamera);

    // rStats has finished determining render call now
    if (config.isDev && config.isShowingStats) {
      Stats.end();
    }

    // Delta time is sometimes needed for certain updates
    // const delta = this.clock.getDelta();

    // Call any vendor or module frame updates here
    TWEEN.update();
    this.controls!.threeControls.update();

    // RAF
    requestAnimationFrame(this.render.bind(this)); // Bind the main class instead of window object
  }
}
