<template>
  <div :class="['container', {game: advanced}]">
    <div class="left">
      <button class="leave-button" @click="$router.push('/')">{{$t('leave')}}</button>
      <h1>{{$t('roomId')}}: {{game.id}}</h1>
      <div v-if="!game.started">
        <h2>{{$t('chooseStart')}}</h2>
        <button class="select-button"
          v-for="player, index in game.players" :key="player.name" @click="startGame(index)">
          {{player.name}}
        </button>
      </div>
      <div class="players">
        <h1>{{$t('players')}}</h1>
        <hr/>
        <transition-group class="flip-list" name="flip-list" tag="ul">
          <li v-for="player in playersSorted" v-bind:key="player.name">
            <span v-if="player === me" class="player-text">
              <b>{{player.name}}: {{player.progress + 1}}</b>
            </span>
            <span v-else class="player-text">
              {{player.name}}: {{player.progress + 1}}
            </span>
          </li>
        </transition-group>
      </div>
    </div>
    <advanced-board v-if="advanced" :game="game" :debug="false"></advanced-board>
    <div v-if="advanced" class="center-spacer"></div>
    <game-board v-else :game="game" :debug="false"></game-board>
    <div class="right">
      <h1 v-if="currentPlayer" :class="{'myturn-text' : myTurn}">
        {{$t('turn')}}: {{currentPlayer.name}}
      </h1>
      <div class="dice-wrapper">
        <dice :current="diceCurrent[0]"></dice>
        <dice v-if="game.dice[1] > 0" :current="diceCurrent[1]"></dice>
      </div>
      <div v-if="myTurn && !game.finished">
        <div v-if="isChoose">
          <h1 v-if="currentPlayer.progress === 8">{{$t('chooseWinner')}}</h1>
          <h1 v-if="currentPlayer.progress === 27">{{$t('choosePlayer')}}</h1>
          <button v-for="player in game.players" :key="player.name" @click="choose(player.name)">
            {{player.name}}
          </button>
        </div>
        <button v-else class="button"
          @click="turn">
          {{$t(buttonText)}}
        </button>
      </div>
      <div v-if="currentPoint">
        <h2><b>{{currentPoint.title}}</b></h2>
        <div v-for="desc,index in currentPoint.description" :key="index">
          <h4>{{desc}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import Dice from '@/components/Dice.vue';
import GameBoard from '@/components/GameBoard.vue';
import AdvancedBoard from '@/components/3DBoard.vue';
import { io, Socket } from 'socket.io-client';
import {
  Action, GameState, Player, Point,
} from '@/utils/types';
import { points } from '../utils/points';

type ComponentData = {
  socket: Socket;
  game: GameState;
  diceCurrent: number[];
}

export default defineComponent({
  name: 'Home',
  props: {
    advanced: Boolean,
  },
  data(): ComponentData {
    return {
      game: {
        id: '',
        players: [],
        turn: 0,
        started: false,
        finished: false,
        dice: [1, 1],
      },
      socket: io(process.env.VUE_APP_SOCKET_LOCATION),
      diceCurrent: [1, 1],
    };
  },
  mounted(): void {
    this.game.id = this.$route.params.gameId as string;
    if (!this.name) this.$router.push('/');
    this.socket.on('connect', () => {
      this.socket.emit('join', { gameId: this.game.id, player: this.name });
    });
    this.socket.on('state', (game: GameState) => {
      this.game = game;
    });
    this.socket.on('noGame', () => {
      this.$router.push('/');
    });
  },
  computed: {
    name(): string {
      return this.$store.state.name;
    },
    me(): Player | undefined {
      return this.game.players.find((player) => player.name === this.name);
    },
    myTurn(): boolean {
      return this.name === this.currentPlayer?.name && this.action !== Action.none;
    },
    currentPlayer(): Player | null {
      return this.game.players[this.game.turn];
    },
    isChoose(): boolean {
      return this.action === Action.choose;
    },
    isEndTurn(): boolean {
      return this.action === Action.next;
    },
    buttonText(): string {
      let result;
      switch (this.action) {
        case Action.rollTwo:
          result = 'throwDice';
          break;
        case Action.rollOne:
          result = 'throwDie';
          break;
        case Action.choose:
          result = 'choose';
          break;
        case Action.move:
          result = 'move';
          break;
        case Action.next:
          result = 'endTurn';
          break;
        default:
          result = 'none';
      }
      return result;
    },
    action(): Action {
      const player = this.currentPlayer;
      if (!player) return Action.none;
      return player.action;
    },
    currentPoint(): Point | null {
      if (!this.currentPlayer) return null;
      return points[this.$i18n.locale][this.currentPlayer.progress];
    },
    playersSorted(): Player[] {
      return [...this.game.players].sort((a, b) => b.progress - a.progress);
    },
  },
  methods: {
    updateDiceNumbers(times: number): void {
      if (times > 1) {
        this.diceCurrent[0] = (Math.round(Math.random() * 5) + 1);
        this.diceCurrent[1] = (Math.round(Math.random() * 5) + 1);
        setTimeout(() => {
          this.updateDiceNumbers(times - 1);
        }, 100);
      } else {
        this.diceCurrent = this.game.dice;
      }
    },
    turn() {
      this.socket.emit('turn', this.game.id);
      if (!this.isEndTurn) {
        this.updateDiceNumbers(10);
      }
    },
    choose(playerName: string) {
      this.socket.emit('choose', { gameId: this.game.id, playerName });
    },
    startGame(turn: number) {
      this.socket.emit('start', { gameId: this.game.id, turn });
    },
  },
  components: {
    Dice,
    GameBoard,
    AdvancedBoard,
  },
});
</script>
