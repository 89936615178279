<template>
  <div :class="classes"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

type Dice = {
  currentNumber: number;
  finalNumber: number;
}
export default defineComponent({
  name: 'dice-component',
  props: {
    current: Number,
  },
  computed: {
    classes(): string {
      return `dice dice_${this.current ? this.current : ''}`;
    },
  },
});
</script>

<style>
.dice {
  background-image:url('http://www.clker.com/cliparts/e/7/4/4/1194991183406177705six-sided_dice_faces_lio_01.svg.med.png');
  height:49px;
  width:49px;
  display:inline-block;
  margin:5px;
}
.dice_1 {
  background-position:0px 0px;
}
.dice_2 {
  background-position:-50px 0px;
}
.dice_3 {
  background-position:-101px 0px;
}
.dice_4 {
  background-position:-151px 0px;
}
.dice_5 {
  background-position:-201px 0px;
}
.dice_6 {
  background-position:-251px 0px;
}
</style>
