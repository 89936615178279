
import { GameState, Player, Point } from '@/utils/types';
import { defineComponent, PropType } from 'vue';
import { locations, points } from '../utils/points';
import Piece from '../utils/three-components/piece';
import Main, { loadFont, fitPieceOnPoint } from '../utils/three-components/main';

type PieceData = {
  x: number;
  y: number;
  owner: string;
}

type ComponentData = {
  ratio: number;
  pieces: PieceData[];
}

// Vue completely fucks with everything stored in data which completely borks threejs
// so unfortunately this shit is the only way to go
let main: Main;
let pieceMeshes: Piece[];

export default defineComponent({
  name: 'game-board',
  props: {
    game: {
      required: true,
      type: Object as PropType<GameState>,
    },
    debug: Boolean as PropType<boolean>,
  },
  data(): ComponentData {
    return {
      ratio: 1,
      pieces: [],
    };
  },
  async mounted(): Promise<void> {
    window.addEventListener('resize', this.updateRatio);
    const font = await loadFont();
    main = new Main(this.$refs.container as HTMLElement, font, this.boardReady);
    setTimeout(() => this.updateRatio(), 50);
    this.updatePositions();
  },
  unmounted(): void {
    window.removeEventListener('resize', this.updateRatio);
  },
  watch: {
    game(newGame, oldGame): void {
      const currentPointId = oldGame.players[oldGame.turn]?.progress;
      const nextPointId = newGame.players[newGame.turn]?.progress;
      const shouldMove = currentPointId !== nextPointId;
      this.updatePositions(shouldMove);
    },
  },
  computed: {
    currentPoint(): Point | null {
      if (!this.currentPlayer) return null;
      return points[this.$i18n.locale][this.currentPlayer.progress];
    },
    currentPlayer(): Player | null {
      return this.game ? this.game.players[this.game.turn] : null;
    },
  },
  methods: {
    async boardReady() {
      const pieces = await Promise.all(this.game.players.map(async (x) => {
        const point = points[this.$i18n.locale][x.progress];
        const { location } = main!.getPointLocation(point.id, this.$i18n.locale);
        if (location) {
          const pieceMesh = await main!.createPiece(location, x.name);
          return new Piece(pieceMesh, x);
        }
        return undefined;
      }));
      pieceMeshes = pieces.filter((x) => x !== undefined) as Piece[];
      this.updatePositions(!!this.currentPoint);
    },
    setPosition(piece: PieceData, progress: number): PieceData {
      const location = locations[progress];
      const [y, x] = location;
      /* eslint-disable-next-line */
      piece.x = x * this.ratio; piece.y = y * this.ratio;
      return { ...piece, x, y };
    },
    updateRatio(): void {
      const board = this.$refs.board as Element | null;
      if (!board) return;
      this.ratio = board.clientHeight / 900;
      this.updatePositions();
    },
    updatePositions(shouldMove?: boolean): void {
      const playerPositions = this.game.players.reduce((acc, val) => {
        const point = points[this.$i18n.locale][val.progress].id;
        if (point) {
          return acc[point]
            ? { ...acc, [point]: [...acc[point], val.name].sort() }
            : { ...acc, [point]: [val.name] };
        }
        return acc;
      }, {} as {[key: number]: string[]});
      this.game.players.forEach(async (player) => {
        const point = points[this.$i18n.locale][player.progress];
        const { location: pointLocation } = main.getPointLocation(point.id, this.$i18n.locale);
        let location = pointLocation;
        let pieceObject = pieceMeshes
          ? pieceMeshes.find((p) => p.player.name === player.name)
          : undefined;
        if (!pieceObject) {
          if (location) {
            const pieceMesh = await main!.createPiece(location, player.name);
            pieceObject = new Piece(pieceMesh, player);
            pieceMeshes = pieceMeshes ? [...pieceMeshes, pieceObject] : [pieceObject];
          }
        }
        if (pieceObject && pointLocation) {
          if (!!playerPositions[point.id] && playerPositions[point.id].length > 1) {
            location = fitPieceOnPoint(
              playerPositions[point.id].indexOf(player.name),
              pointLocation!,
              pieceObject.diameter,
            );
          }
          pieceObject.moveTo(location!, 2000, shouldMove);
        }
      });
      if (shouldMove) {
        main!.moveTo(this.currentPoint!.id, this.$i18n.locale);
      }
    },
  },
});
