import { Tween } from '@tweenjs/tween.js';
import {
  Line3, Object3D, PerspectiveCamera, Renderer, Vector3,
} from 'three';

import fullconfig from '../../config';

const config = fullconfig.threeConfig;

// Class that creates and updates the main camera
export default class Camera {
  threeCamera: PerspectiveCamera;

  constructor(renderer: Renderer) {
    const { width } = renderer.domElement;
    const { height } = renderer.domElement;

    // Create and position a Perspective Camera
    this.threeCamera = new PerspectiveCamera(
      config.camera.fov,
      width / height,
      config.camera.near,
      config.camera.far,
    );
    this.threeCamera.position.set(config.camera.posX, config.camera.posY, config.camera.posZ);

    // Initial sizing
    this.updateSize(renderer);

    // Listeners
    window.addEventListener('resize', () => this.updateSize(renderer), false);
  }

  moveTo(time: number, target: Object3D, distance: number) {
    const targetVec = new Vector3(target.position.x, target.position.y, target.position.z);
    const currentVec = new Vector3(
      this.threeCamera.position.x,
      this.threeCamera.position.y,
      this.threeCamera.position.z,
    );
    const line = new Line3(currentVec, targetVec);
    const d = line.at(distance / line.distance(), targetVec);
    const current = {
      x: this.threeCamera.position.x,
      y: this.threeCamera.position.y,
      z: this.threeCamera.position.z,
    };

    const tween = new Tween(current).to({ x: d.x, y: d.y, z: d.z }, time);
    tween.onUpdate((value) => this.threeCamera.position.set(value.x, value.y, value.z));
  }

  updateSize(renderer: Renderer) {
    // Update camera aspect ratio with window aspect ratio
    this.threeCamera.aspect = renderer.domElement.width / renderer.domElement.height;

    // Always call updateProjectionMatrix on camera change
    this.threeCamera.updateProjectionMatrix();
  }
}
