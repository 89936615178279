import {
  Scene,
  WebGLRenderer,
  ACESFilmicToneMapping,
  PMREMGenerator,
  PCFSoftShadowMap,
  Camera,
  sRGBEncoding,
} from 'three';

import fullconfig from '../../config';

const config = fullconfig.threeConfig;

// Main webGL renderer class
export default class Renderer {
    threeRenderer: THREE.WebGLRenderer

    container: HTMLElement

    scene: Scene

    constructor(scene: Scene, container: HTMLElement) {
    // Properties
      this.scene = scene;
      this.container = container;

      // Create WebGL renderer and set its antialias
      this.threeRenderer = new WebGLRenderer({ antialias: true });
      this.threeRenderer.toneMapping = ACESFilmicToneMapping;
      this.threeRenderer.toneMappingExposure = 1;
      this.threeRenderer.outputEncoding = sRGBEncoding;

      const pmremGenerator = new PMREMGenerator(this.threeRenderer);
      pmremGenerator.compileEquirectangularShader();

      // Set clear color to fog to enable fog or to hex color for no fog
      this.threeRenderer.setClearColor(scene.fog!.color);
      this.threeRenderer.setPixelRatio(window.devicePixelRatio); // For retina

      // Appends canvas
      container.appendChild(this.threeRenderer.domElement);

      // Shadow map options
      this.threeRenderer.shadowMap.enabled = true;
      this.threeRenderer.shadowMap.type = PCFSoftShadowMap;

      // Get anisotropy for textures
      config.maxAnisotropy = this.threeRenderer.capabilities.getMaxAnisotropy();

      // Initial size update set to canvas container
      this.updateSize();

      // Listeners
      document.addEventListener('DOMContentLoaded', () => this.updateSize(), false);
      window.addEventListener('resize', () => this.updateSize(), false);
    }

    updateSize() {
      this.threeRenderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    }

    render(scene: Scene, camera: Camera) {
    // Renders scene to canvas target
      this.threeRenderer.render(scene, camera);
    }
}
